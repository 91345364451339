var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-leave-sidebar","visible":_vm.isAddLeaveSidebarActive,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"change":function (val) { return _vm.$emit('update:is-add-leave-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Add Leave ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-overlay',{attrs:{"show":_vm.formLoading,"rounded":"sm"}},[_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"alert d-flex font-weight-bold"},[_c('label',{staticClass:" font-small-4 "},[_vm._v(" Use this form to create leave for practice staff. If you want to add leave to yourself, it won’t require approval.")])]),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Add Leave For")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Staff","rules":_vm.admin_leave ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"Select Staff","reduce":function (name) { return name.id; },"options":_vm.options,"disabled":_vm.disableStaff,"multiple":""},model:{value:(_vm.StaffMember),callback:function ($$v) {_vm.StaffMember=$$v},expression:"StaffMember"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-checkbox',{attrs:{"value":true},model:{value:(_vm.admin_leave),callback:function ($$v) {_vm.admin_leave=$$v},expression:"admin_leave"}},[_vm._v(" Add leave for myself ")])],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"cursor-pointer"},[_vm._v(" Leave Days")]),_c('label',[_vm._v(" Leave is based on working hours. Please add the working hours under the profile section before adding leave. ")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Start Date")]),_c('validation-provider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-datepicker',{staticClass:"form-control",attrs:{"id":"start_date","date-format-options":{ year: '2-digit', month: '2-digit', day: '2-digit' },"placeholder":"Select","start-weekday":"1","disabled":_vm.disableStartDate,"locale":"en-UK","trim":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("End Date")]),_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-datepicker',{staticClass:"form-control",attrs:{"id":"end_date","date-format-options":{ year: '2-digit', month: '2-digit', day: '2-digit' },"disabled":_vm.disableEndDate,"start-weekday":"1","min":_vm.minDate,"placeholder":"Select","locale":"en-UK","trim":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-form',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.leaveItems),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Date")]),_c('validation-provider',{ref:"date",refInFor:true,attrs:{"bails":false,"name":'Date'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-datepicker',{staticClass:"form-control",attrs:{"date-format-options":{ year: '2-digit', month: '2-digit', day: '2-digit' },"placeholder":"Select","start-weekday":"1","min":_vm.min,"locale":"en-UK","trim":""},model:{value:(_vm.leaveItems[index].date),callback:function ($$v) {_vm.$set(_vm.leaveItems[index], "date", $$v)},expression:"leaveItems[index].date"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The Date field is required")]):_vm._e()]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v("Start Time")]),_c('validation-provider',{ref:"startTime",refInFor:true,attrs:{"bails":false,"name":'Start Time'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"ClockIcon"}})],1),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', },"placeholder":"Select"},model:{value:(_vm.leaveItems[index].startTime),callback:function ($$v) {_vm.$set(_vm.leaveItems[index], "startTime", $$v)},expression:"leaveItems[index].startTime"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The Start Time field is required")]):_vm._e()]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v("End Time")]),_c('validation-provider',{ref:"endTime",refInFor:true,attrs:{"bails":false,"name":'End Time'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"ClockIcon"}})],1),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', },"placeholder":"Select"},model:{value:(_vm.leaveItems[index].endTime),callback:function ($$v) {_vm.$set(_vm.leaveItems[index], "endTime", $$v)},expression:"leaveItems[index].endTime"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The End Time field is required")]):_vm._e()]}}],null,true)})],1),_c('b-col',{staticStyle:{"margin-top":"25px"},attrs:{"cols":"12","md":"2"}},[(_vm.leaveItems.length == index + 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"5px"},attrs:{"size":"20","variant":"outline-white"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"17"}})],1):_vm._e(),(_vm.leaveItems.length > 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"5px"},attrs:{"size":"20","variant":"outline-white"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusCircleIcon","size":"17"}})],1):_vm._e()],1)],1)}),1),_c('b-form'),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('label',[_vm._v("Reason")]),_c('validation-provider',{attrs:{"name":"Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"options":_vm.leaveReasonOptions,"reduce":function (leaveReason) { return leaveReason.id; },"label":"name","placeholder":"Select"},model:{value:(_vm.leaveReason),callback:function ($$v) {_vm.leaveReason=$$v},expression:"leaveReason"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.showSicknesInput)?_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('label',[_vm._v("Sickness Type")]),_c('validation-provider',{attrs:{"name":"Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"options":_vm.sicknessTypeOptions,"reduce":function (sicknessType) { return sicknessType.id; },"label":"name","placeholder":"Select"},model:{value:(_vm.sicknessType),callback:function ($$v) {_vm.sicknessType=$$v},expression:"sicknessType"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex  mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary","disabled":_vm.saveDisable()},on:{"click":function($event){return _vm.addLeave()}}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"text-primary ml-1 shadow",attrs:{"type":"button","variant":"outline-white"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }